import { Component, For } from "solid-js";
import { builderImage } from "~/utils/builder";
import { A } from "@solidjs/router";
import { Carousel } from "../ui";
import {
  PHOTOFRAME,
  GALLERYFRAME,
  MIRROR,
  MOULDING,
  ProductType,
} from "~/utils/products";
import { Img } from "~/components/image";

// TODO:
// the list data strange - qtyCount refers to the amount of products in a given 'Category'
// ie if a collection (Arber), exists in both Roma One and Simply Roma - it represents
// the number in that category. However clicking does not constrain you to that category,
// but sends you the collection as a whle - so while it may say '2' in that category, when
// user clicks it may show 100 results...possibly persist the current Category? Needs improvement regardless

export type CollectionCardFields = {
  list: {
    name: string;
    count: number;
    qtyCount: number;
    img: string;
    galleryFrameImage?: string;
    photoFrameImage?: string;
  }[];
  type: ProductType;
};

export const CollectionSlider: Component<CollectionCardFields> = (props) => {
  const imageType = (
    type: ProductType
  ): "img" | "galleryFrameImage" | "photoFrameImage" => {
    switch (type) {
      case MOULDING:
        return "img";
      case PHOTOFRAME:
        return "photoFrameImage";
      case GALLERYFRAME:
        return "galleryFrameImage";
      case MIRROR:
        return "img";
      default:
        return "img";
    }
  };

  const path = (type: ProductType): string => {
    switch (type) {
      case PHOTOFRAME:
        return "&category=Photo%20Frames";
      case GALLERYFRAME:
        return "&category=Gallery%20Frames";
      default:
        return "";
    }
  };

  return (
    <Carousel>
      <For each={props.list}>
        {(item) => (
          <div class="snap-start pl-1 pr-1 first:ml-0 last:mr-0 shrink-0 w-1/9">
            <A href={`/shop/?collection=${item.name}${path(props.type)}`}>
              <div class=" w-[150px] flex flex-col gap-2 cursor-pointer group">
                <div
                  class="w-full aspect-square bg-roma-grey flex flex-col justify-center"
                  classList={{
                    "p-4": props.type !== GALLERYFRAME,
                    "p-0": props.type == GALLERYFRAME,
                  }}
                >
                  <Img
                    src={builderImage({
                      src: item[imageType(props.type)],
                      width: 150,
                      height: 150,
                      quality: 60,
                    })}
                    width={150}
                    height={150}
                    layout="constrained"
                    alt={item.name}
                  />
                </div>
                <div class="flex flex-col">
                  <h4 class="font-bold">{item.name}</h4>
                  <p class="text-sm group-hover:text-roma-blue transition-colors">
                    {item.qtyCount} Styles
                  </p>
                </div>
              </div>
            </A>
          </div>
        )}
      </For>
    </Carousel>
  );
};