import { type Component, Show } from "solid-js";
import { builderImage } from "~/utils/builder";
import Button from "../Button";
import { type CataloguePromoModel } from "~/services/builder/types";

export const ProductPromo: Component<CataloguePromoModel["data"]> = (props) => {
  return (
    <div
      class="w-full bg-cover flex flex-col justify-between p-3 bg-roma-grey"
      style={{
        "background-image": `url(${builderImage({
          src: props.bgImage,
          width: 400,
          height: 400,
          quality: 85,
        })})`,
      }}
      classList={{
        "aspect-square": props.layout === "Square",
        "max-md:aspect-square h-full": props.layout === "Full",
      }}
    >
      <p
        class="max-sm:text-2xl font-bold text-xl"
        classList={{ "text-white": props.textColour === "White" }}
      >
        {props.textContent}
      </p>

      <Show when={props.buttonText && props.buttonUrl}>
        <div class="self-start">
          <Button
            style={props.buttonColour === "White" ? "solidWhite" : "solid"}
            href={props.buttonUrl}
          >
            <span class="max-sm:text-lg max-sm:px-3 px-1 text-sm">
              {props.buttonText}
            </span>
          </Button>
        </div>
      </Show>
    </div>
  );
};
